import React from "react";

const NodeIcon = () => {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M112.678 30.334L68.535 4.729C65.754 3.145 62.111 3.145 59.308 4.729L14.82 30.334C11.951 31.985 10 35.088 10 38.407V89.549C10 92.868 11.992 95.972 14.862 97.632L26.591 104.32C32.218 107.092 33.777 107.092 36.337 107.092C44.671 107.092 48.999 102.053 48.999 93.264V42.774C49 42.061 49.445 41 48.744 41H43.122C42.41 41 41 42.061 41 42.773V93.263C41 97.159 37.384 101.036 30.798 97.743L18.676 90.73C18.254 90.5 18 90.037 18 89.549V38.407C18 37.925 18.463 37.441 18.891 37.194L63.269 11.633C63.684 11.398 64.271 11.398 64.684 11.633L108.647 37.188C109.068 37.441 109.001 37.91 109.001 38.407V89.549C109.001 90.037 109.093 90.512 108.678 90.747L64.545 116.323C64.167 116.55 63.675 116.55 63.26 116.323L51.943 109.574C51.602 109.376 51.191 109.305 50.863 109.488C47.718 111.271 47.134 111.508 44.184 112.531C43.457 112.784 42.385 113.223 44.592 114.46L59.39 123.214C60.806 124.034 62.417 124.46 64.037 124.46C65.679 124.46 67.286 124.034 68.703 123.214L112.679 97.632C115.55 95.96 117.001 92.868 117.001 89.549V38.407C117 35.088 115.549 31.993 112.678 30.334V30.334ZM77.727 81.445C66 81.445 63.418 78.21 62.557 72.379C62.455 71.751 61.923 71 61.283 71H55.553C54.844 71 54.273 71.86 54.273 72.566C54.273 80.032 58.333 89.078 77.727 89.078C91.765 89.078 99.815 83.623 99.815 73.969C99.815 64.397 93.348 61.885 79.733 60.083C65.971 58.264 64.573 57.345 64.573 54.121C64.573 51.463 65.757 47.918 75.947 47.918C85.051 47.918 88.407 49.872 89.788 56.009C89.907 56.586 90.434 57 91.029 57H96.783C97.137 57 97.474 56.857 97.722 56.604C97.963 56.332 98.089 55.991 98.058 55.625C97.165 45.056 90.145 40.131 75.946 40.131C63.314 40.131 55.78 45.465 55.78 54.406C55.78 64.104 63.277 66.784 75.402 67.983C89.907 69.405 91.035 71.525 91.035 74.378C91.035 79.334 87.057 81.445 77.727 81.445Z"
        fill="#83CD29"
      />
    </svg>
  );
};

export default NodeIcon;
