const HTML5Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.56942 21.3517L1.69348 0.311432H22.3065L20.4285 21.3486L11.9874 23.6886L3.56942 21.3517Z" fill="#E44D26" />
      <path d="M12 21.9L18.8209 20.0089L20.4257 2.03175H12V21.9Z" fill="#F16529" />
      <path
        d="M12 9.83532H8.58524L8.34936 7.19269H12V4.61232H5.52917L5.59105 5.30457L6.22517 12.4159H12V9.83532ZM12 16.5371L11.9885 16.5403L9.11474 15.7641L8.93117 13.7063H6.34049L6.70199 17.7579L11.9882 19.2253L12 19.2221V16.5371Z"
        fill="#EBEBEB"
      />
      <path
        d="M11.991 9.83532V12.4159H15.1685L14.8691 15.7626L11.991 16.5394V19.2242L17.2813 17.7579L17.3201 17.322L17.9265 10.5283L17.9893 9.83532H17.294H11.991ZM11.991 4.61232V7.19269H18.2242L18.276 6.61294L18.3937 5.30457L18.4554 4.61232H11.991Z"
        fill="white"
      />
    </svg>
  );
};

export default HTML5Icon;
