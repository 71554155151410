const LinkedinIcon = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.4507 0.00033762H3.5493C3.08953 -0.00604778 2.633 0.0781988 2.20579 0.248266C1.77858 0.418334 1.38906 0.67089 1.05947 0.991513C0.729879 1.31214 0.466676 1.69455 0.28489 2.1169C0.103104 2.53925 0.00629793 2.99328 0 3.45305V44.8374C0.00629793 45.2971 0.103104 45.7512 0.28489 46.1735C0.466676 46.5959 0.729879 46.9783 1.05947 47.2989C1.38906 47.6195 1.77858 47.8721 2.20579 48.0421C2.633 48.2122 3.08953 48.2965 3.5493 48.2901H44.4507C44.9105 48.2965 45.367 48.2122 45.7942 48.0421C46.2214 47.8721 46.6109 47.6195 46.9405 47.2989C47.2701 46.9783 47.5333 46.5959 47.7151 46.1735C47.8969 45.7512 47.9937 45.2971 48 44.8374V3.45305C47.9937 2.99328 47.8969 2.53925 47.7151 2.1169C47.5333 1.69455 47.2701 1.31214 46.9405 0.991513C46.6109 0.67089 46.2214 0.418334 45.7942 0.248266C45.367 0.0781988 44.9105 -0.00604778 44.4507 0.00033762V0.00033762ZM14.5594 40.4188H7.3159V18.6885H14.5594V40.4188ZM10.9376 15.6462C9.93866 15.6462 8.98061 15.2494 8.27424 14.543C7.56786 13.8366 7.17103 12.8786 7.17103 11.8796C7.17103 10.8806 7.56786 9.9226 8.27424 9.21623C8.98061 8.50985 9.93866 8.11301 10.9376 8.11301C11.4681 8.05286 12.0053 8.10542 12.514 8.26726C13.0227 8.4291 13.4915 8.69657 13.8897 9.05216C14.2879 9.40774 14.6065 9.84342 14.8246 10.3307C15.0428 10.8179 15.1555 11.3458 15.1555 11.8796C15.1555 12.4135 15.0428 12.9413 14.8246 13.4285C14.6065 13.9158 14.2879 14.3515 13.8897 14.7071C13.4915 15.0627 13.0227 15.3301 12.514 15.492C12.0053 15.6538 11.4681 15.7064 10.9376 15.6462V15.6462ZM40.6841 40.4188H33.4406V28.7569C33.4406 25.8353 32.4024 23.9279 29.7706 23.9279C28.9561 23.9339 28.163 24.1893 27.4982 24.6599C26.8334 25.1305 26.3287 25.7935 26.0523 26.5597C25.8634 27.1272 25.7815 27.7249 25.8109 28.3223V40.3947H18.5674C18.5674 40.3947 18.5674 20.6442 18.5674 18.6643H25.8109V21.7307C26.4689 20.5889 27.4259 19.6483 28.5789 19.0101C29.7319 18.3719 31.0372 18.0603 32.3541 18.109C37.1831 18.109 40.6841 21.2237 40.6841 27.9118V40.4188Z"
        fill="#60AFED"
      />
    </svg>
  );
};

export default LinkedinIcon;
