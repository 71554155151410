import React from "react";

const CSSIcon = () => {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.814 114.123L8.76001 1.35201H119.24L109.176 114.106L63.933 126.649L18.814 114.123V114.123Z" fill="#1572B6" />
      <path d="M64.001 117.062L100.56 106.926L109.161 10.572H64.001V117.062Z" fill="#33A9DC" />
      <path d="M64.001 51.429H82.303L83.567 37.266H64.001V23.435H98.683L98.351 27.146L94.951 65.26H64.001V51.429V51.429Z" fill="white" />
      <path
        d="M64.083 87.349L64.022 87.367L48.619 83.208L47.634 72.177H33.752L35.689 93.894L64.02 101.757L64.083 101.739V87.349V87.349Z"
        fill="#EBEBEB"
      />
      <path d="M81.1269 64.675L79.4609 83.197L64.0349 87.361V101.751L92.3889 93.893L92.5969 91.556L95.0029 64.675H81.1269Z" fill="white" />
      <path
        d="M64.0481 23.435V37.266H30.6401L30.3631 34.158L29.7331 27.146L29.4021 23.435H64.0481V23.435ZM64.0011 51.431V65.262H48.7921L48.5151 62.154L47.8841 55.142L47.5541 51.431H64.0011V51.431Z"
        fill="#EBEBEB"
      />
    </svg>
  );
};

export default CSSIcon;
