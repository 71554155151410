import React from "react";

const GithubIconLarge = () => {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21_42)">
        <path
          d="M63.992 0C28.656 0 3.69318e-05 28.768 3.69318e-05 64.256C-0.0144 77.7244 4.20415 90.8567 12.0595 101.797C19.9148 112.737 31.0097 120.932 43.776 125.224C46.976 125.816 48.144 123.832 48.144 122.128C48.144 120.6 48.088 116.56 48.056 111.2C30.256 115.08 26.496 102.584 26.496 102.584C23.592 95.16 19.392 93.184 19.392 93.184C13.576 89.2 19.824 89.28 19.824 89.28C26.248 89.736 29.624 95.904 29.624 95.904C35.336 105.72 44.608 102.888 48.256 101.24C48.832 97.088 50.488 94.256 52.32 92.648C38.112 91.024 23.168 85.512 23.168 60.896C23.168 53.88 25.664 48.144 29.76 43.648C29.096 42.024 26.904 35.488 30.384 26.648C30.384 26.648 35.76 24.92 47.984 33.232C53.206 31.8049 58.5946 31.0785 64.008 31.072C69.4218 31.0827 74.8101 31.8118 80.032 33.24C92.248 24.928 97.616 26.656 97.616 26.656C101.104 35.504 98.912 42.032 98.256 43.656C102.36 48.152 104.832 53.888 104.832 60.904C104.832 85.584 89.872 91.016 75.616 92.608C77.912 94.592 79.96 98.512 79.96 104.504C79.96 113.096 79.88 120.024 79.88 122.128C79.88 123.848 81.032 125.848 84.28 125.216C97.034 120.914 108.115 112.716 115.959 101.778C123.804 90.8407 128.016 77.7158 128 64.256C128 28.768 99.344 0 63.992 0Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_42">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GithubIconLarge;
