import React from "react";

const ReduxIcon = () => {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.4772 19.792C48.2719 13.7333 57.3012 10.7413 63.4559 10.5867C80.7145 10.256 87.9092 31.9893 88.4639 40.6293L96.4532 43.0507C94.6132 16.5653 78.3199 2.672 62.7306 2.672C48.1119 2.66667 34.6132 13.408 29.1732 29.1573C21.7439 50.1547 26.6186 70.3413 35.7279 86.496C34.9332 87.5467 34.4532 89.3973 34.6132 91.1733C34.7679 96.0213 38.8426 100.059 43.7226 100.059H44.0426C45.25 100.024 46.4383 99.7484 47.5376 99.2478C48.637 98.7473 49.6252 98.032 50.4441 97.1441C51.263 96.2561 51.8961 95.2134 52.3061 94.0772C52.7162 92.941 52.8949 91.7343 52.8319 90.528C52.8319 85.68 48.6772 81.6533 43.8026 81.6533H43.4772C43.1572 81.6533 42.6826 81.6533 42.2772 81.808C35.6479 70.5013 32.8479 58.384 33.8932 45.312C34.5279 35.456 37.7279 26.896 43.4772 19.792V19.792Z"
        fill="#6C4EB0"
      />
      <path
        d="M4.97068 111.456C10.3253 119.531 21.2747 124.693 33.2587 124.693C36.4533 124.693 39.8133 124.459 43.088 123.648C63.8667 119.611 79.6107 107.013 88.64 88.4427L88.7147 88.2773C93.3494 87.872 96.944 83.7493 96.7093 78.8213C96.464 73.8933 92.4693 69.9413 87.6 69.9413H87.2747C82.2453 70.1067 78.3253 74.384 78.4853 79.4773C78.6453 82.0587 79.6907 84.16 81.12 85.6907C75.5307 96.6773 67.1413 104.747 54.432 111.536C45.8827 116.053 36.8533 117.755 28.064 116.544C20.7147 115.499 14.9653 112.176 11.4453 106.853C6.17601 98.7733 5.69601 90.0587 10.0853 81.3387C13.2907 75.04 18.08 70.4373 21.28 68.1707C20.48 66.0747 19.52 62.5227 19.04 59.8613C-4.62399 76.976 -2.21866 100.389 4.97068 111.456V111.456Z"
        fill="#6C4EB0"
      />
      <path
        d="M125.147 107.893C129.936 98.2027 129.541 81.8933 117.312 67.92L117.237 68.08C104.859 53.3813 86.6347 45.3067 65.8507 45.3067H63.1307C61.7814 42.32 58.6667 40.4587 55.1467 40.4587H54.9067C49.8774 40.4587 45.9574 44.8267 46.1227 49.9093C46.2827 54.752 50.3574 58.7947 55.2321 58.7947H55.6267C57.3611 58.7206 59.0373 58.1479 60.4544 57.1452C61.8714 56.1425 62.9692 54.7523 63.6161 53.1413H66.5761C78.8907 53.1413 90.5494 56.7733 101.184 63.8773C109.328 69.2907 115.168 76.3947 118.443 84.8747C121.312 91.8187 121.157 98.6027 118.203 104.261C113.648 113.141 105.973 117.829 95.8294 117.829C89.4347 117.829 83.2054 115.808 80.0054 114.357C78.0907 115.963 74.8907 118.629 72.5707 120.251C79.6001 123.472 86.7147 125.333 93.5841 125.333C109.168 125.333 120.747 116.459 125.147 107.893V107.893Z"
        fill="#6C4EB0"
      />
    </svg>
  );
};

export default ReduxIcon;
