import React from "react";

const TypeScriptIcon = () => {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21_71)">
        <path
          d="M0 64V128H128V0H0V64ZM77.6693 104.672C78.2453 104.347 80.3947 103.104 82.4267 101.925L86.1067 99.792L86.88 100.933C87.9573 102.576 90.3093 104.832 91.7333 105.584C95.8187 107.739 101.424 107.435 104.187 104.955C104.712 104.513 105.134 103.962 105.423 103.34C105.713 102.718 105.862 102.041 105.861 101.355L105.856 101.104V101.115C105.856 99.632 105.669 98.9813 104.896 97.8613C103.904 96.4427 101.872 95.248 96.1013 92.7413C89.4987 89.8987 86.656 88.1333 84.0533 85.328C82.4533 83.5092 81.2653 81.366 80.5707 79.0453L80.5387 78.928C80.0533 77.12 79.9307 72.5867 80.3147 70.7627C81.6747 64.3787 86.4907 59.936 93.44 58.6133C95.696 58.1867 100.939 58.3467 103.152 58.896V58.9013C106.405 59.712 108.88 61.1573 111.157 63.5147C112.336 64.7733 114.085 67.0667 114.224 67.6213C114.267 67.7813 108.699 71.5147 105.328 73.6107C105.205 73.6907 104.715 73.1627 104.171 72.352C102.517 69.952 100.795 68.9173 98.1547 68.736C94.272 68.4693 91.776 70.5013 91.7973 73.8933L91.792 74.0907C91.792 74.896 91.9947 75.6533 92.352 76.3147L92.3413 76.288C93.1947 78.0533 94.784 79.1147 99.7547 81.264C108.923 85.2107 112.843 87.808 115.28 91.504C118 95.6267 118.613 102.213 116.763 107.109C114.736 112.432 109.696 116.048 102.603 117.243C100.411 117.632 95.2107 117.573 92.8533 117.147C87.712 116.229 82.8373 113.691 79.8293 110.357C78.6507 109.061 76.352 105.664 76.496 105.424C76.8657 105.141 77.2581 104.89 77.6693 104.672V104.672ZM28.0427 64.3467C28.0427 61.4987 28.1013 59.12 28.1813 59.0667C28.2453 58.9813 38.384 58.9387 50.672 58.96L73.0453 59.024L73.104 64.2453L73.1467 69.488H56.48V116.827H44.6933V69.488H28.0427V64.3467V64.3467Z"
          fill="#1677C7"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_71">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TypeScriptIcon;
