import React from "react";

const JavaScriptIcon = () => {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21_54)">
        <path d="M0 0H128V128H0V0Z" fill="#F7DF1E" />
        <path
          d="M33.656 106.966L43.451 101.038C45.341 104.389 47.06 107.224 51.1835 107.224C55.136 107.224 57.6285 105.678 57.6285 99.6635V58.7645H69.657V99.8335C69.657 112.292 62.354 117.963 51.699 117.963C42.0765 117.963 36.491 112.98 33.6555 106.965M76.1905 105.677L85.9845 100.007C88.563 104.217 91.914 107.31 97.842 107.31C102.827 107.31 106.005 104.818 106.005 101.381C106.005 97.257 102.74 95.796 97.2405 93.391L94.234 92.101C85.5555 88.4075 79.799 83.7675 79.799 73.9725C79.799 64.9505 86.6725 58.0765 97.413 58.0765C105.06 58.0765 110.559 60.7405 114.511 67.7L105.145 73.715C103.083 70.0205 100.85 68.56 97.4125 68.56C93.8895 68.56 91.6555 70.794 91.6555 73.715C91.6555 77.3235 93.8895 78.785 99.0445 81.019L102.052 82.3075C112.277 86.69 118.033 91.1575 118.033 101.21C118.033 112.037 109.527 117.965 98.0995 117.965C86.93 117.965 79.7125 112.638 76.19 105.678"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_54">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JavaScriptIcon;
