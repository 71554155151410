const OpenFolderIcon = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 10.5341C24 10.7983 23.868 11.0796 23.604 11.3778L19.3124 16.4403C18.9462 16.875 18.4332 17.2436 17.7733 17.5462C17.1134 17.8487 16.5024 18 15.9404 18H2.04364C1.75412 18 1.49654 17.9446 1.27089 17.8338C1.04524 17.723 0.932411 17.5398 0.932411 17.2841C0.932411 17.0199 1.0644 16.7386 1.32837 16.4403L5.62001 11.3778C5.98617 10.9432 6.49921 10.5746 7.15912 10.272C7.81905 9.96947 8.43002 9.81819 8.99202 9.81819H22.8888C23.1783 9.81819 23.4359 9.87359 23.6615 9.98438C23.8872 10.0952 24 10.2784 24 10.5341ZM19.6189 6.13638V8.18182H8.99202C8.19159 8.18182 7.35284 8.38424 6.47578 8.78907C5.59873 9.19389 4.90049 9.70313 4.38106 10.3168L0.0766339 15.3793L0.0127768 15.456C0.0127768 15.4219 0.0106473 15.3686 0.00638839 15.2962C0.00212946 15.2237 0 15.1705 0 15.1364V2.86364C0 2.07955 0.281 1.40626 0.843 0.843748C1.405 0.281249 2.0777 0 2.8611 0H6.94838C7.73177 0 8.40447 0.281249 8.96648 0.843748C9.52848 1.40625 9.80948 2.07954 9.80948 2.86364V3.27273H16.7579C17.5412 3.27273 18.2139 3.55398 18.7759 4.11647C19.3379 4.67897 19.6189 5.35228 19.6189 6.13638Z"
        fill="#90A3AE"
      />
    </svg>
  );
};

export default OpenFolderIcon;
